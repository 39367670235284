<template>
  <div class="toggle" :class="{ disabled }">
    <FormKit
      :id="id"
      v-model="value"
      type="checkbox"
      :name="name ?? id"
      inner-class="w-[60px] h-[22px] rounded-[18px] relative innerToggle overflow-hidden cursor-pointer"
      :input-class="
        disabled && hasWhiteBg
          ? 'bg-background-base, cursor-no-drop'
          : '!hidden'
      "
      decorator-class="w-[18px] h-[18px] rounded-[100%] absolute top-[2px] bottom-[2px] thumb"
      :disabled="disabled"
    >
      <template #label>
        <FormLabel :label="label" />
      </template>
      <template #decoratorIcon>
        <div
          class="w-[98px] h-[22px] flex flex-row relative top-[-2px] left-[-40px] thumb-content"
        >
          <div
            class="w-[40px] font-roboto text-[12px] text-white flex justify-center items-center select-none font-bold pl-[2px]"
          >
            {{ t('formFields.toggle.yes') }}
          </div>
          <div
            class="relative flex items-center justify-center w-[18px] h-[18px] bg-white z-10 rounded-[100%] top-[2px]"
          >
            <Icon
              icon-class="fas fa-check"
              class="absolute checkIcon text-primary-base"
            />
            <Icon
              icon-class="fas xmark"
              class="absolute xIcon text-border-light"
            />
          </div>
          <div
            class="w-[40px] font-roboto text-[12px] text-white flex justify-center items-center select-none font-bold pr-[2px]"
          >
            {{ t('formFields.toggle.no') }}
          </div>
        </div>
      </template>
    </FormKit>
  </div>
</template>
<script setup lang="ts">
import { FormLabel, Icon } from '@/complib';
import { FormKit } from '@formkit/vue';

defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  id: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  hasWhiteBg: {
    type: Boolean,
    default: true,
  },
});

const value = defineModel<boolean>();
const { t } = useTrans();
</script>
<style lang="postcss" scoped>
.toggle.disabled {
  @apply opacity-50;
  * {
    cursor: no-drop;
  }
}
.toggle :deep(.innerToggle) {
  .thumb {
    transition: all 0.2s ease-in-out;
    left: 2px;

    .checkIcon {
      @apply opacity-0;
      transition: all 0.2s ease-in-out;
    }
    .xIcon {
      @apply opacity-100;
      transition: all 0.2s ease-in-out;
    }

    .thumb-content {
      @apply bg-border-light;
      transition: all 0.2s ease-in-out;
    }
  }

  input:checked + .thumb {
    left: 40px;
    .checkIcon {
      @apply opacity-100;
    }
    .xIcon {
      @apply opacity-0;
    }
    .thumb-content {
      @apply bg-primary-base;
    }
  }
}
</style>
